import React, { FC, useEffect, useState } from "react"
import { globalHistory } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import { generateConditionalClassName, isClientSide } from "@utils"

import Button, { BUTTON_SIZES } from "@components/Button/Button"
import Icon, { ICON_TYPES } from "@components/Icon/Icon"

import { FixedCtaBarDIV } from "./FixedCtaBar.styles"
import { FixedCtaBarQueryQuery } from "@graphqlTypes"

export enum FIXED_CTA_BAR_VARIANTS {
  DEMO = "DEMO",
  TRIAL = "TRIAL",
}

const FIXED_CTA_BAR_DEMO_LS_KEY = `hs--fixed-cta-bar--is-dismissed`
const FIXED_CTA_BAR_TRIAL_LS_KEY = `hs--fixed-cta-bar--trial--is-dismissed`
const ZI_DATA_LS_KEY = `_ziVisitorInfo`

const PATHNAMES_TO_HIDE_ON = [
  "/lp/get-a-demo/",
  "/pricing/contact-based/",
]

const FixedCtaBar: FC<{
  forceShow?: boolean
  variant?: FIXED_CTA_BAR_VARIANTS
}> = ({
  forceShow = false,
  variant = FIXED_CTA_BAR_VARIANTS.DEMO,
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [shouldShow, setShouldShow] = useState<boolean>(forceShow)
    const isTrial = variant === FIXED_CTA_BAR_VARIANTS.TRIAL

    const {
      demoGraphic,
      trialGraphic,
    } = useStaticQuery<FixedCtaBarQueryQuery>(graphql`
      query FixedCtaBarQuery {
        demoGraphic: contentfulAsset(contentful_id: {eq: "4yw5Ibs8zDufWIyoEaXXLp"}) {
          imgixImage {
            gatsbyImageData(width: 100)
          }
        }
        trialGraphic: contentfulAsset(contentful_id: {eq: "1CQqneRBftlbHGrq8zhPb1"}) {
          imgixImage {
            gatsbyImageData(width: 100)
          }
        }
      }
    `)

    useEffect(() => {
      if (!isClientSide()) return

      if (window.location.search.includes("show_fixed_cta_bar=1")) {
        setShouldShow(true)
        return
      }

      const isDismissed = window.localStorage.getItem(
        isTrial ?
          FIXED_CTA_BAR_TRIAL_LS_KEY :
          FIXED_CTA_BAR_DEMO_LS_KEY
      )

      if (isDismissed) return

      const visitorData = JSON.parse(window.localStorage.getItem(ZI_DATA_LS_KEY) || "{}")

      if (
        !visitorData.ziDetails ||
        !visitorData.ziDetails.employeeCount ||
        visitorData.ziDetails.employeeCount < 11 ||
        !visitorData.ziDetails.country ||
        ["India", "Russia", "China"].includes(visitorData.ziDetails.country)
      )
        return

      setShouldShow(true)
    }, [])

    useEffect(() => {
      if (!isClientSide() || !shouldShow) return

      if (forceShow) {
        setIsVisible(true)
        return
      }

      const handleScroll = () => {
        if (window.scrollY >= document.body.scrollHeight / 3 - window.innerHeight) {
          setIsVisible(true)
          window.removeEventListener("scroll", handleScroll)
        }
      }

      window.addEventListener("scroll", handleScroll)
      globalHistory.listen(action => {
        if (!action) return

        if (
          action.action === "PUSH" &&
          !PATHNAMES_TO_HIDE_ON.includes(action.location.pathname)
        )
          setIsVisible(false)
      })

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }, [shouldShow])

    const handleClickClose = () => {
      window.localStorage.setItem(
        isTrial ? FIXED_CTA_BAR_TRIAL_LS_KEY : FIXED_CTA_BAR_DEMO_LS_KEY,
        "true"
      )
      setIsVisible(false)
    }

    return (
      <FixedCtaBarDIV className={generateConditionalClassName({
        "is-visible": isVisible,
        "is-trial": isTrial,
      })}>
        <div className="gradient-border" />
        <div className="content">
          {isTrial && trialGraphic?.imgixImage?.gatsbyImageData && (
            <GatsbyImage
              alt="Avatars of members of our Sales Team"
              image={trialGraphic.imgixImage.gatsbyImageData}
            />
          )}
          {!isTrial && demoGraphic?.imgixImage?.gatsbyImageData && (
            <GatsbyImage
              alt="Avatars of members of our Sales Team"
              image={demoGraphic.imgixImage.gatsbyImageData}
            />
          )}
          <p className="font-size--L">
            {isTrial ?
              <>Get started in minutes with a free account.</> :
              <>We’d love to show you the magic of Help&nbsp;Scout.</>
            }
          </p>
          <Button
            className="desktop"
            href={isTrial ? "{{signupPlusUrl}}" : "{{demoUrl}}?demofixedcta=true"}
            onClick={handleClickClose}
            size={BUTTON_SIZES.S}
          >
            {isTrial ? "Sign Up Free" : "Book a Demo"}
          </Button>
          <Button
            className="mobile"
            size={BUTTON_SIZES.M}
            href={isTrial ? "{{signupPlusUrl}}" : "{{demoUrl}}?demofixedcta=true"}
            onClick={handleClickClose}
          >
            {isTrial ? "Sign Up Free" : "Book a Demo"}
          </Button>
          <button
            aria-label="Close"
            className="close-button"
            onClick={handleClickClose}
          >
            <Icon
              color="var(--color--basics-white)"
              type={ICON_TYPES["close"]}
            />
          </button>
        </div>
      </FixedCtaBarDIV>
    )
  }

export default FixedCtaBar
